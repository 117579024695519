<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" class="ma-0 pa-0" max-width="600">
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                class="custom-btn"
                block
            >   REGULAR
                <v-icon
                    small
                    class="ma-3"
                    color="white"
                    size="60"
                >
                    mdi-list-status
                </v-icon>
            </v-btn>
        </template>
        <v-card class="pa-8 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                LISTA REGULAR
            </v-card-title>
            <v-card-text>
                <v-data-table 
                    :items="pacientes"
                    :headers="headers"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="true"
                    >  
                        <template v-slot:top>
                            <v-text-field
                            v-model="search"
                            label="Pesquisar"
                            class="mx-4"
                            ></v-text-field>
                        </template>    
                      
                        <template v-slot:item.chamar="{item}">
                            <Regular :current-item="item" @atualiza-regular="get_listas('mutirao-sus/pacientes/?etapa=regular', 'pacientes')"/>
                        </template>    
                        
                        <template v-slot:item.dt_nascimento="{item}">
                            {{ calcularIdade(item.dt_nascimento) }}
                        </template>    
                      
                        <!-- <template v-slot:item.editar="{item}">
                            <Sus :current-item="item"/>
                        </template>     -->
                    </v-data-table>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import api from '@/http'
    export default{
        name:'ListaRegular',
        components:{
            Regular:()=>import('./Regular.vue'),
            // Sus:()=>import('./Sus.vue'),
        },
        data:()=>({
            dialog:false,
            loading:false,
            search:'',
            msg:'',
            pacientes:[],
            headers:[
                {text:'Prontuário',sorted:true,value:'id'},
                {text:'Nome',sorted:true,value:'nome'},
                {text:'Idade.',sorted:true,value:'dt_nascimento'},
                {text:'Chamar',sorted:true,value:'chamar'},
                // {text:'Editar',sorted:true,value:'editar'},
            ]
        }),
        methods:{
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            get_listas(url, objeto){
                this.loading = true
                api.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
        },
        mounted(){
            this.get_listas('mutirao-sus/pacientes/?etapa=regular', 'pacientes')
        }
    }
</script>
<style>

.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}
</style>